import React, { useEffect, useState } from "react";
import sanityClient from "../../client.js"; 
import { Modal, Button} from "@mui/material";
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import ReactMarkdown from "react-markdown";

export default function Classes() {
  const [classesData, setClassesData] = useState(null);
  const [expandedStates, setExpandedStates] = useState({});
  const [selectedClass, setSelectedClass] = useState(null);


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "classes"] | order(orderNumber asc) {
          _id,
          className,
          description,
          formattedDescription,
          classPrice,
          mainImage {
            asset->{_id, url}
          },
        }`
      )
      .then((data) => setClassesData(data))
      .catch(console.error);
  }, []);
  
  const handleOpenModal = (selectedClass) => {
    setSelectedClass(selectedClass);
  };

  const handleCloseModal = () => {
    setSelectedClass(null);
  };

  const renderMarkdownBlocks = (blocks) => {
    return blocks.map((block, index) => {
      if (block._type === "block") {
        return (
          <ReactMarkdown key={index}>
            {block.children.map((child) => child.text).join("")}
          </ReactMarkdown>
        );
      } else {
        return null;
      }
    });
  };


  // Displaying the classes data, or nothing if the data is not yet available
  return (

                
<div className="cards-container"  >
{classesData &&
        classesData.map((classes, index) => (
          <div className="cards" key={index}>
            {classes.mainImage && (
              <img src={classes.mainImage.asset.url} alt={classes.className} />
            )}
            <div className="cards-textbox">
              {classes.className && (
                <>
                  <button onClick={() => handleOpenModal(classes)}>
                    <h3>{classes.className}</h3>
                  </button>

                  {classes.description && (
                    <>
                      <button
                        onClick={() => handleOpenModal(classes)}
                        className="read-more-blog"
                      >
                        Olvass Tovább
                      </button>
                    </>
                  )}
                </>
              )}

              {classes.classPrice && (
                <p className="price">{classes.classPrice}</p>
              )}
            </div>
          </div>
        ))}



      {/* Modal */}
      <Modal
        open={selectedClass !== null}
        onClose={handleCloseModal}
        aria-labelledby="class-sub-modal"
        aria-describedby="class-details"
      >
         <Fade in={selectedClass !== null}>
          <Box className="instructor-modal">
<button className="modal-close-button-cards" onClick={handleCloseModal}><ClearOutlinedIcon /></button>
          {selectedClass && (
            <>
            <div className="instructor-sub-side-modal">
            
            {selectedClass && (
              <>
                {selectedClass.mainImage && (
                  <img
                    src={selectedClass.mainImage.asset.url}
                    alt={selectedClass.className}
                  />
                )}
                <h2>{selectedClass.className}</h2>
                {selectedClass.classPrice && (
                  <p className="modal-price">{selectedClass.classPrice}</p>
                )}
              </>
            )}

               </div>

           

            <div className="instructor-main-side-modal">
                  {selectedClass.formattedDescription && renderMarkdownBlocks(selectedClass.formattedDescription)}
                </div>

          
            </>
          )}

        </Box></Fade>
      </Modal>


    </div>     

  );
}
